import React, { useEffect, useState } from 'react';
import { getPaginatedData } from '../lib/services/baseServices';
import { getAllCookies } from '../lib/helpers/utils';
import queryString from 'query-string';

const LeftTableHeader = ({
    perPage, 
    handleItemPerPageSelect, 
    paginationDropdownOptions, 
    planBoardData, 
    handleSort, 
    sortDirectionANumber, 
    sortDirectionChassisNummer,
    sortDirectionLicencePlate,
    sortDirectionReceiptDatePlanned,
    sortDirectionDeliveryDate,
    sortDirectionModel,
    sortDirectionVestigingen,
    sortDirectionCustomer,
    setIsDataFetching
}) => {
    const isBypassed = queryString.parse(window.location.search).bypass_login
    const [authToken,] = useState(getAllCookies(isBypassed).authToken);
    const [headerTitles, setHeaderTitles] = useState([]);

    const getTableHeaders = async () => {
        setIsDataFetching(true)
        const response = await getPaginatedData("api/trucks/headerTranslationsLists", {}, authToken);
    
        if (response) {
            setHeaderTitles(response);
        }
        setIsDataFetching(false)
    }

    useEffect(()=> {
        getTableHeaders();
    }, [])

    return (
        <div className="custom-sticky-position">

            <div className="row row-heading">
                <div className="col-xl-2">
                    <select className="form-select form-select-sm select-record-per-page" value={perPage} onChange={handleItemPerPageSelect}>
                        {
                            paginationDropdownOptions?.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-xl-10">
                    {perPage} out of {planBoardData?.totalItems ? planBoardData.totalItems : 0} record shown
                </div>
            </div>
            <div className="row row-heading">
                <div className="col-xl-3"></div>
                <div className="col-xl-3"></div>
                <div className="col-xl-3"></div>
                <div className="col-xl-3"></div>
            </div>
            <div className="row row-heading">
                <div className="filter-item" onClick={() => handleSort("a_number", sortDirectionANumber === 'asc' ? 'desc' : 'asc')}>
                    {/* OM nummer {sortDirectionANumber === "desc" */}
                    {headerTitles.length ? headerTitles[0] : ""} {sortDirectionANumber === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionANumber === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("chassis_no", sortDirectionChassisNummer === 'asc' ? 'desc' : 'asc')}>
                    {/* Chassis Nummer {sortDirectionChassisNummer === "desc" */}
                    {headerTitles.length ? headerTitles[1] : ""} {sortDirectionChassisNummer === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionChassisNummer === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("license_plate", sortDirectionLicencePlate === 'asc' ? 'desc' : 'asc')}>
                    {/* Kenteken {sortDirectionLicencePlate === "desc" */}
                    {headerTitles.length ? headerTitles[2] : ""} {sortDirectionLicencePlate === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionLicencePlate === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("customer", sortDirectionCustomer === 'asc' ? 'desc' : 'asc')}>
                    {/* Klantnaam {sortDirectionCustomer === "desc" */}
                    {headerTitles.length ? headerTitles[3] : ""} {sortDirectionCustomer === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionCustomer === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("receipt_date_planned", sortDirectionReceiptDatePlanned === 'asc' ? 'desc' : 'asc')}>
                    {/* Binnenkomstdatum {sortDirectionReceiptDatePlanned === "desc" */}
                    {headerTitles.length ? headerTitles[4] : ""} {sortDirectionReceiptDatePlanned === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionReceiptDatePlanned === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("planned_delivery_date", sortDirectionDeliveryDate === 'asc' ? 'desc' : 'asc')}>
                    {/* Verwachte afleverdatum {sortDirectionDeliveryDate === "desc" */}
                    {headerTitles.length ? headerTitles[5] : ""} {sortDirectionDeliveryDate === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionDeliveryDate === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("model", sortDirectionModel === 'asc' ? 'desc' : 'asc')}>
                    {/* Model {sortDirectionModel === "desc" */}
                    {headerTitles.length ? headerTitles[6] : ""} {sortDirectionModel === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionModel === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
                <div className="filter-item" onClick={() => handleSort("vestigingen", sortDirectionVestigingen === 'asc' ? 'desc' : 'asc')}>
                    {/* Vestigingen {sortDirectionVestigingen === "desc" */}
                    {headerTitles.length ? headerTitles[7] : ""} {sortDirectionVestigingen === "desc"
                        ? <i className="fa-solid fa-arrow-up"></i>
                        : sortDirectionVestigingen === "asc"
                            ? <i className="fa-solid fa-arrow-down"></i>
                            : null}
                </div>
            </div>
        </div>
    );
};

export default LeftTableHeader;