import React from 'react';
import Draggable from 'react-draggable';
import { getFormattedDate } from '../lib/helpers/utils';
import { Button, Tooltip } from 'antd';
import { config } from './../lib/helpers/config';

const PlanBoardItem = ({ 
    dateList, 
    planBoardData, 
    taskParentRef, 
    draggedItemPlanningTaskTrucksId, 
    data, 
    indexDateList, 
    handleDragStart, 
    handleDragStop ,
    item,
    loadPlanningListPerTrucks,
    indexTruckList,
    planning_task_trucks_id,
    itemIndex
}) => {
    const planning_task_details = dateList.planning_task_id[itemIndex] ? planBoardData[`planning_task_details_${dateList.planning_task_id[itemIndex]}`] : null;
    const note_details = dateList.planning_task_id[itemIndex] ? planBoardData[`note_lists_${planning_task_trucks_id}`] : null;

    return (
        <Draggable
            bounds={taskParentRef}
            cancel='.note-container'
            position={{ x: 0, y: 0 }}
            disabled={planning_task_trucks_id ? false : true}
            defaultClassNameDragging="dragging-item"
            onStart={(e, position) => handleDragStart(e, position, planning_task_trucks_id, dateList.render_count ? true : false)}
            onStop={(e, position) => handleDragStop(e, position)}
            onMouseDown={(e) =>{
                e.preventDefault();
            }}
            id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`}
        >
            <div id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`} className={`col`}>
                <Tooltip
                    color="#fff"
                    title={<div className="note-container">

                        <p className="title">{planning_task_details?.supplier ? planning_task_details?.supplier : "N/A"}</p>
                        <p className="title">{planning_task_details?.email ? planning_task_details?.email : "N/A"}</p>
                        <p className="title blue">{planning_task_details?.phone ? planning_task_details?.phone : "N/A"}</p>

                        <a target="_blank" href={`${config.portal_url}/dbtables/trucks/edit/${item.id}?redirectFrom=planboard`} className="url" rel="noreferrer">Open trucks in new tab</a>
                        {
                            note_details?.length > 0 && note_details.map((note, index) => (
                                <div key={index}>
                                    <p className="subtitle">On {note.date} {note.name}:</p>
                                    <p className="title">{note.notes}</p>
                                </div>
                            ))
                        }
                        <div>
                            <button className='back-to-list-btn' onClick={(e)=> {
                                e.preventDefault();
                                e.stopPropagation();
                                loadPlanningListPerTrucks(item.id)
                            }}>See Details</button>
                        </div>
                    </div>}
                    placement="bottom"
                    overlayInnerStyle={{ width: 323, height: '100%', padding: 0 }}
                    zIndex={10}
                >

                    <div id={`${getFormattedDate(data.dateColumnLists[indexDateList].date, data.year)}`}
                        className={`task-box task-blue text-center ${draggedItemPlanningTaskTrucksId === planning_task_trucks_id ? "dragging-active" : ""}`}
                        style={
                            planning_task_details ? {
                                backgroundColor: planning_task_details.color_bg,
                                color: planning_task_details.color,
                                opacity: !draggedItemPlanningTaskTrucksId || (planning_task_trucks_id === draggedItemPlanningTaskTrucksId) ? 1 : .3,
                            } : {
                                opacity: 1,
                            }
                        }
                    >
                        {
                            dateList.render_count > 0 && note_details?.length > 0 && (
                                <span className="comment-count">{note_details[0].count}</span>
                            )
                        }
                        {planning_task_details ? planning_task_details?.code : ""}
                    </div>
                </Tooltip>
            </div>
        </Draggable>
    );
};

export default PlanBoardItem;