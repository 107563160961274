import React, { useEffect, useRef, useState } from 'react'
import { getPaginatedData, postData } from '../lib/services/baseServices';
import '../styles/main.css';
import { message } from 'antd';
import { getAllCookies, paginationDropdownOptions } from '../lib/helpers/utils';
import Pusher from 'pusher-js';
import queryString from 'query-string';
import FullScreenLoader from '../components/FullScreenLoader';
import ColumnSearchField from '../components/ColumnSearch/ColumnSearchField';
import LeftTableHeader from '../components/LeftTableHeader';
import Pagination from '../components/Pagination';
import Header from '../components/Header';
import CalenderDataView from '../components/CalenderDataView';
import WeekScroller from '../components/WeekScroller';
import StickyTableHeader from '../components/StickyTableHeader';
import TruckListRowData from '../components/TruckListRowData';
import UnAuthorized from '../components/UnAuthorized';

const Main = () => {
  const [planBoardData, setPlanBoardData] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [truckListColumnData, setTruckListColumnData] = useState([
    { name: 'a_number', options: [] },
    { name: 'chassis_no', options: [] },
    { name: 'license_plate', options: [] },
    { name: 'customer', options: [] },
    { name: 'receipt_date_planned', options: [] },
    { name: 'planned_delivery_date ', options: [] },
    { name: 'model', options: [] },
    { name: 'vestigingen', options: [] },
  ]);
  const [dateListData, setDateListData] = useState([]);
  const scrollRightDivRef = useRef();
  const scrollLeftDivRef = useRef();
  const [scrollType, setScrollType] = useState('');

  const [sortDirectionANumber, setSortDirectionANumber] = useState('');
  const [sortDirectionChassisNummer, setSortDirectionChassisNummer] = useState('');
  const [sortDirectionLicencePlate, setSortDirectionLicensePlate] = useState('');
  const [sortDirectionCustomer, setSortDirectionCustomer] = useState('');
  const [sortDirectionReceiptDatePlanned, setSortDirectionReceiptDatePlanned] = useState('');
  const [sortDirectionDeliveryDate, setSortDirectionDeliveryDate] = useState('');
  const [sortDirectionModel, setSortDirectionModel] = useState('');
  const [sortDirectionVestigingen, setSortDirectionVestigingen] = useState('');

  const [columnFilterParams, setColumnFilterParams] = useState({
    column_filter_a_number: [],
    column_filter_chassis_no: [],
    column_filter_license_plate: [],
    column_filter_customer: [],
    column_filter_receipt_date_planned: '',
    column_filter_planned_delivery_date: '',
    column_filter_model: [],
    column_filter_vestigingen: [],
  })

  const [planBoardStartWeek, setPlanBoardStartWeek] = useState('');
  const [planBoardEndWeek, setPlanBoardEndWeek] = useState('');
  const [planBoardStartYear, setPlanBoardStartYear] = useState('');
  const [planBoardEndYear, setPlanBoardEndYear] = useState('');
  const [selectedFilterDate, setSelectedFilterDate] = useState('');
  const [selectedFilterPlanningTaskId, setSelectedFilterPlannigTaskId] = useState('');
  const [isWeekDataFetching, setIsWeekDataFetching] = useState(false);
  const taskParentRef = useRef();
  const leftFilterBarRef = useRef(null);
  const [rightFilterBarHeight, setRightFilterBarHeight] = useState(0);
  const [draggedItemPlanningTaskTrucksId, setDraggedItemPlanningTaskTrucksId] = useState('');
  const [draggedItemDate, setDraggedItemDate] = useState('');
  const [isDragDropOnProcessing, setIsDragDropOnProcessing] = useState(false);
  const [isForceReload, setIsForceReload] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);
  
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [planningListResponse, setPlanningListResponse] = useState([]);

  const [pusher, setPusher] = useState(null);

  let isRendered = true;
  const isBypassed = queryString.parse(window.location.search).bypass_login
  const [authToken, setAuthToken] = useState(getAllCookies(isBypassed).authToken);

  useEffect(()=> {
    if(isRendered){
      isRendered = false;
    }
  },[])

  useEffect(()=> {
    setPusher(new Pusher('342d649b633007d997aa', {
      cluster: 'eu',
    }))
  }, [isRendered])

  // code for scrolling
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getInitialColumnFilterData();
  }, [])

  useEffect(() => {
    if (isForceReload) {
      getPlanBoardData();
    }
  }, [perPage, page, columnFilterParams, selectedFilterPlanningTaskId, isForceReload]);

  useEffect(() => {
    if (authToken && leftFilterBarRef) {
      setRightFilterBarHeight(leftFilterBarRef.current.clientHeight + 1);
    }
  }, [columnFilterParams, isForceReload]);



  const getPlanBoardData = async () => {
    setIsDataFetching(true)
    const params = {
      item_per_page: perPage,
      page: page,
      ...renderSortDirectionParams(),
      ...columnFilterParams,
      ...renderPlannboardParams(),
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    const response = await getPaginatedData("api/trucks/listing", params, authToken);

    if (response) {
      setPageCount(response.totalItems / perPage)
      setPlanBoardData(response);
      setOrderData(prev => response.data);
      setDateListData(response.dateLists);
      setIsDataFetching(false);
      // setTruckListColumnData(Object.keys(response.data[0]).filter(item => item !== "id").map(item => {return {name: item, options: []}}))
    }
  }

  const getInitialColumnFilterData = async () => {
    setIsDataFetching(true);
    const queryParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    setColumnFilterParams(queryParams);
  
    const truckListColumnDataCopy = [...truckListColumnData];
    const promises = truckListColumnDataCopy.map(async (tempColumn) => {
      if (tempColumn.name === 'a_number' && queryParams.column_filter_a_number) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_a_number: queryParams.column_filter_a_number }));
      }
      if (tempColumn.name === 'chassis_no' && queryParams.column_filter_chassis_no) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_chassis_no: queryParams.column_filter_chassis_no }));
      }
      if (tempColumn.name === 'license_plate' && queryParams.column_filter_license_plate) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_license_plate: queryParams.column_filter_license_plate }));
      }
      if (tempColumn.name === 'customer' && queryParams.column_filter_customer) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_customer: queryParams.column_filter_customer }));
      }
      if (tempColumn.name === 'model' && queryParams.column_filter_model) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_model: queryParams.column_filter_model }));
      }
      if (tempColumn.name === 'vestigingen' && queryParams.column_filter_vestigingen) {
        setColumnFilterParams((prev) => ({ ...prev, column_filter_vestigingen: queryParams.column_filter_vestigingen }));
      }
  
      const params = {
        column: tempColumn.name,
        search: '',
      };
  
      if (tempColumn.name === 'date_of_sale' || tempColumn.name === 'receipt_date_planned' || tempColumn.name === 'planned_delivery_date') {
        return Promise.resolve(null); // Skip API call for date columns
      } else {
        const response = await getPaginatedData("api/trucks/columnSearch", params, authToken);

        if (response) {
          const tempOptions = response.data.map((item, index) => ({
            id: `${tempColumn.name}-${item[tempColumn.name]}-${index}`,
            value: item[tempColumn.name],
          }));
  
          const currentItem = truckListColumnDataCopy.find((item) => item.name === tempColumn.name);
          const currentFiltered = truckListColumnDataCopy.filter((item) => item.name !== tempColumn.name);
  
          currentItem.options = tempOptions;
          return currentItem;
        } else {
          return null;
        }
      }
    });
  
    const updatedTruckListColumnData = await Promise.all(promises);
  
    // Filter out null values and update the state once
    const filteredTruckListColumnData = updatedTruckListColumnData.filter((item) => item !== null);
    setTruckListColumnData([...filteredTruckListColumnData]);
  
    setIsDataFetching(false);
    setIsForceReload(true);
  };
  

  const handleItemPerPageSelect = (e) => {
    setPerPage(e.target.value);
  }

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  }

  const handleWeekScroll = async (type) => {
    const firstWeekNumber = typeof dateListData[0].weekNumber === "string" ? parseInt(dateListData[0].weekNumber) : dateListData[0].weekNumber;
    const lastWeekNumber = typeof dateListData[2].weekNumber === "string" ? parseInt(dateListData[2].weekNumber) : dateListData[2].weekNumber;
    const firstYear = dateListData[0].year;
    const lastYear = dateListData[2].year;
    let newWeek;
    let newYear;
    if (type === 'right') {
      newWeek = lastWeekNumber === 52 ? 1 : lastWeekNumber + 1;
      newYear = lastWeekNumber === 52 ? lastYear + 1 : lastYear;
    } else if (type === 'left') {
      newWeek = firstWeekNumber === 1 ? 52 : firstWeekNumber - 1;
      newYear = firstWeekNumber === 1 ? firstYear - 1 : firstYear;
    }

    const params = {
      item_per_page: perPage,
      ...renderSortDirectionParams(),
      ...columnFilterParams,
      plannboardScrollWeek: newWeek,
      plannboardScrollYear: newYear,
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    setIsWeekDataFetching(true);
    const response = await getPaginatedData("api/trucks/listing", params, authToken);

    if (response) {
      const { dateLists, ...rest } = response;

      if (type === 'right') {
        setDateListData(prev => [...prev, ...dateLists]);
        setPlanBoardData(prev => { return { ...prev, ...rest } });
        setPlanBoardEndWeek(newWeek);
        setPlanBoardEndYear(newYear);
        setPlanBoardStartWeek(() => firstWeekNumber === 52 ? 1 : firstWeekNumber + 1);
        setPlanBoardStartYear(() => firstWeekNumber === 52 ? firstYear + 1 : firstYear);
      } else {
        setDateListData(prev => [...dateLists, ...prev]);
        setPlanBoardData(prev => { return { ...prev, ...rest } });
        setPlanBoardStartWeek(newWeek);
        setPlanBoardStartYear(newYear);
        setPlanBoardEndWeek(() => lastWeekNumber === 1 ? 52 : lastWeekNumber - 1);
        setPlanBoardEndYear(() => lastWeekNumber === 1 ? lastYear - 1 : lastYear);
      }
     
      setScrollType(type);
      setTimeout(() => {
        if (type === 'right') {
          setDateListData(prev => prev.slice(-3));
          setScrollType("")
        } else {
          setDateListData(prev => prev.slice(0, 3));
          setScrollType("")
        }
        setIsWeekDataFetching(false);
      }, 1000);

    }
  }

  useEffect(() => {
    if (scrollType === 'right') {
      if (scrollRightDivRef) {
        scrollRightDivRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
      }
    } else if (scrollType === 'left') {
      if (scrollLeftDivRef) {
        scrollLeftDivRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
      }
    }
  }, [dateListData])

  const handleSort = async (field_name, type) => {
    setPage(1);
    if (field_name === 'a_number') {
      setSortDirectionANumber(type);
    } else if (field_name === "chassis_no") {
      setSortDirectionChassisNummer(type);
    } else if (field_name === "license_plate") {
      setSortDirectionLicensePlate(type);
    } else if (field_name === 'customer') {
      setSortDirectionCustomer(type);
    } else if (field_name === 'receipt_date_planned') {
      setSortDirectionReceiptDatePlanned(type);
    } else if (field_name === 'planned_delivery_date') {
      setSortDirectionDeliveryDate(type);
    } else if (field_name === 'model') {
      setSortDirectionModel(type);
    } else if (field_name === 'vestigingen') {
      setSortDirectionVestigingen(type);
    }

    const params = {
      item_per_page: perPage,
      page: 1,
      sort_direction_a_number: field_name === 'a_number' ? type : sortDirectionANumber,
      sort_direction_license_plate: field_name === 'a_number' ? type : sortDirectionLicencePlate,
      sort_direction_chassis_no: field_name === 'chassis_no' ? type : sortDirectionChassisNummer,
      sort_direction_customer: field_name === 'customer' ? type : sortDirectionCustomer,
      sort_direction_receipt_date_planned: field_name === 'receipt_date_planned' ? type : sortDirectionReceiptDatePlanned,
      sort_direction_planned_delivery_date: field_name === 'planned_delivery_date' ? type : sortDirectionDeliveryDate,
      sort_direction_model: field_name === 'model' ? type : sortDirectionModel,
      sort_direction_vestigingen: field_name === 'vestigingen' ? type : sortDirectionVestigingen,
      ...columnFilterParams,
      ...renderPlannboardParams(),
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
    }
    const response = await getPaginatedData("api/trucks/listing", params, authToken);

    setPage(1);
    if (response) {
      setPlanBoardData(response);
      setPageCount(response.totalItems / perPage)
      setOrderData(response.data);
      setDateListData(response.dateLists);
    }
  }

  const handleColumnFilterChange = async (value, field_name) => {
    const params = {
      column: field_name,
      search: value,
    }
    const response = await getPaginatedData("api/trucks/columnSearch", params, authToken);

    if (response) {
      const tempOptions = response.data.map((item, index) => { return item ? { id: `${field_name}-${item[field_name]}-${index}}`, value: item[field_name] } : null }).filter(inner => inner !== null);
      const tempTruckListColumnData = truckListColumnData;
      const currentItem = tempTruckListColumnData.filter(item => item.name === field_name);
      const currentFiltered = tempTruckListColumnData.filter(item => item.name !== field_name);

      currentItem[0].options = tempOptions;
      setTruckListColumnData([...currentFiltered, ...currentItem]);
    }
  }

  const handleColumnFilter = (value, field_name) => {
    setPage(1);
    if (field_name === 'a_number') {
      setColumnFilterParams(prev => { return { ...prev, column_filter_a_number: value } })
    } else if (field_name === "chassis_no") {
      setColumnFilterParams(prev => { return { ...prev, column_filter_chassis_no: value } })
    } else if (field_name === "license_plate") {
      setColumnFilterParams(prev => { return { ...prev, column_filter_license_plate: value } })
    } else if (field_name === 'customer') {
      setColumnFilterParams(prev => { return { ...prev, column_filter_customer: value } })
    } else if (field_name === 'model') {
      setColumnFilterParams(prev => { return { ...prev, column_filter_model: value } })
    } else if (field_name === 'vestigingen') {
      setColumnFilterParams(prev => { return { ...prev, column_filter_vestigingen: value } })
    }
  }

  const onChangeFilterDatePicker = (date, field_name) => {
    if (field_name === "receipt_date_planned") {
      setColumnFilterParams(prev => { return { ...prev, column_filter_receipt_date_planned: date ? date.format("DD-MM-YYYY") : "" } })
    } else if (field_name === "planned_delivery_date") {
      setColumnFilterParams(prev => { return { ...prev, column_filter_planned_delivery_date: date ? date.format("DD-MM-YYYY") : "" } })
    }
    setPage(1);
  }

  // Dragging works
  const handleDragStart = (e, position, id, isDraggable) => {
    if (!isDraggable) {
      message.error({ content: "Please drag 1st item of a plan", key: "drag-error" });
      return;
    }
    if (id) {
      setDraggedItemPlanningTaskTrucksId(id);
      setDraggedItemDate(e.target.id);
    }
  }

  const renderSortDirectionParams = () => {
    return {
      sort_direction_a_number: sortDirectionANumber,
      sort_direction_chassis_no: sortDirectionChassisNummer,
      sort_direction_license_plate: sortDirectionLicencePlate,
      sort_direction_customer: sortDirectionCustomer,
      sort_direction_receipt_date_planned: sortDirectionReceiptDatePlanned,
      sort_direction_planned_delivery_date: sortDirectionDeliveryDate,
      sort_direction_model: sortDirectionModel,
      sort_direction_vestigingen: sortDirectionVestigingen,
    }
  }

  const renderPlannboardParams = () => {
    return {
      plannboardStartWeek: planBoardStartWeek,
      plannboardEndWeek: planBoardEndWeek,
      plannboardStartYear: planBoardStartYear,
      plannboardEndYear: planBoardEndYear,
    }
  }

  const handleDragStop = async (e, position) => {
    const currentDate = e.target.id;

    if (currentDate === 'root') {
      message.error({ content: "You can't drag outside plannboard", key: "dragToRoot", duration: 2 });
      setDraggedItemPlanningTaskTrucksId("");
      setDraggedItemDate("");
      return;
    }

    if (currentDate && currentDate !== 'root' && draggedItemPlanningTaskTrucksId) {
      setIsDragDropOnProcessing(true);
      const params = {
        item_per_page: perPage,
        page: page,
        ...renderSortDirectionParams(),
        ...columnFilterParams,
        ...renderPlannboardParams(),
        date_filter: selectedFilterDate,
        planningTaskId: selectedFilterPlanningTaskId,
        renderOnlyCalenderView: 1,
      }

      const data = {
        planning_task_trucks_id: draggedItemPlanningTaskTrucksId,
        start_date: currentDate,
      }

      const response = await postData("api/trucks/movePlanningTasks", data, params, authToken);

      if (response) {
        setDateListData(response.dateLists);
        setIsDragDropOnProcessing(false);
      } else {
        setIsDragDropOnProcessing(false);
      }
    } else {
      message.error({ content: "Something wrong", key: "drag-error" });
    }
    setDraggedItemPlanningTaskTrucksId("");
    setDraggedItemDate("");
  }

  const getCalenderViewData = async () => {
    setIsDataFetching(true);
    const params = {
      item_per_page: perPage,
      page: page,
      ...renderSortDirectionParams(),
      ...columnFilterParams,
      ...renderPlannboardParams(),
      date_filter: selectedFilterDate,
      planningTaskId: selectedFilterPlanningTaskId,
      renderOnlyCalenderView: 1,
      
    }

    const response = await getPaginatedData("api/trucks/listing", params, authToken);

    if (response) {
      // setPlanBoardData(response);
      // setPageCount(response.totalItems / perPage)
      // setOrderData(response.data);
      setDateListData(response.dateLists);
    }
    setIsDataFetching(false);
  }

  useEffect(() => {
    if(pusher){
      const channel = pusher.subscribe('my-channel');
      channel.bind('my-event', function (data) {
        getCalenderViewData();
      });

      return (() => {
        pusher.unsubscribe("my-channel")
      })
    }
  }, [pusher, getCalenderViewData]);

  return (
    <FullScreenLoader isLoading={isDataFetching}>
      <div className="col-lg-11 mx-auto p-3 py-md-3">
        <Header/>
        <main>
          {
            authToken ? (
              <div>
                <StickyTableHeader
                  scrollPosition={scrollPosition}
                  isDragDropOnProcessing={isDragDropOnProcessing}
                  isWeekDataFetching={isWeekDataFetching}
                  handleWeekScroll={handleWeekScroll}
                  taskParentRef={taskParentRef}
                  dateListData={dateListData}
                  scrollRightDivRef={scrollRightDivRef}
                  scrollLeftDivRef={scrollLeftDivRef}
                />
                <div className="row">
                  <div className="col-xl-8">
                    {/* Left table header */}
                    <LeftTableHeader
                      perPage={perPage}
                      handleItemPerPageSelect={handleItemPerPageSelect}
                      paginationDropdownOptions={paginationDropdownOptions}
                      planBoardData={planBoardData}
                      handleSort={handleSort}
                      sortDirectionANumber={sortDirectionANumber}
                      sortDirectionChassisNummer={sortDirectionChassisNummer}
                      sortDirectionLicencePlate={sortDirectionLicencePlate}
                      sortDirectionReceiptDatePlanned={sortDirectionReceiptDatePlanned}
                      sortDirectionDeliveryDate={sortDirectionDeliveryDate}
                      sortDirectionModel={sortDirectionModel}
                      sortDirectionVestigingen={sortDirectionVestigingen}
                      sortDirectionCustomer={sortDirectionCustomer}
                      setIsDataFetching={setIsDataFetching}
                    />

                    {/* rendering column search field */}
                    <ColumnSearchField
                      leftFilterBarRef={leftFilterBarRef}
                      truckListColumnData={truckListColumnData}
                      handleColumnFilter={handleColumnFilter}
                      handleColumnFilterChange={handleColumnFilterChange}
                      onChangeFilterDatePicker={onChangeFilterDatePicker}
                      columnFilterParams={columnFilterParams}
                    />

                    {/* Rendering order data */}
                    <TruckListRowData orderData={orderData}/>
                  </div>
                  <div className="col-xl-4 task-holder">
                    {
                      isDragDropOnProcessing && <div className="loading-overlay">
                        <div className="spinner-border text-secondary"></div>
                      </div>
                    }

                    <WeekScroller isWeekDataFetching={isWeekDataFetching} handleWeekScroll={handleWeekScroll}/>
                    {/* Rendering schedulerdata */}
                    <div className="row calender-schedule-container flex-nowrap" ref={taskParentRef}>
                      {
                        dateListData?.map((data, indexDateListData) => (
                          <CalenderDataView
                            data ={data}
                            indexDateListData={indexDateListData}
                            dateListData={dateListData}
                            scrollRightDivRef={scrollRightDivRef}
                            scrollLeftDivRef={scrollLeftDivRef}
                            rightFilterBarHeight={rightFilterBarHeight}
                            scrollPosition={scrollPosition}
                            selectedFilterDate={selectedFilterDate}
                            selectedFilterPlanningTaskId={selectedFilterPlanningTaskId}
                            planBoardData={planBoardData}
                            taskParentRef={taskParentRef}
                            draggedItemPlanningTaskTrucksId={draggedItemPlanningTaskTrucksId}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            isDetailsModalOpen={isDetailsModalOpen}
                            setIsDetailsModalOpen={setIsDetailsModalOpen}
                            planningListResponse={planningListResponse}
                            setPlanningListResponse={setPlanningListResponse}
                            authToken={authToken}
                            getCalenderViewData={getCalenderViewData}
                            setPage={setPage}
                            setSelectedFilterDate={setSelectedFilterDate}
                            setSelectedFilterPlannigTaskId={setSelectedFilterPlannigTaskId}
                            setIsDataFetching={setIsDataFetching}
                            key={indexDateListData}
                          />
                        ))
                      }
                    </div>

                  </div>
                </div>

                {/* pagination */}
                <Pagination
                  pageCount={pageCount}
                  perPage={perPage}
                  handlePageClick={handlePageClick}
                  handleItemPerPageSelect={handleItemPerPageSelect}
                  paginationDropdownOptions={paginationDropdownOptions}
                />
              </div>
            ) : (
              <UnAuthorized/>
            )
          }
        </main>
        <footer className="pt-3 my-5 text-muted border-top">
          Endroit &copy; 2022
        </footer>
      </div>
    </FullScreenLoader>
  )
}

export default Main;