import React from "react";
import WeekScroller from "./WeekScroller";

const StickyTableHeader = ({
  scrollPosition,
  isDragDropOnProcessing,
  isWeekDataFetching,
  handleWeekScroll,
  taskParentRef,
  dateListData,
  scrollRightDivRef,
  scrollLeftDivRef,
}) => {
  return (
    <div
      className={`row justify-content-end custom-sticky-position ${
        scrollPosition > 296 ? "d-flex" : "d-none"
      }`}
    >
      {/* Working with stickiy header */}
      <div className="col-xl-4 task-holder">
        {isDragDropOnProcessing && (
          <div className="loading-overlay">
            <div className="spinner-border text-secondary"></div>
          </div>
        )}

        <WeekScroller
          isWeekDataFetching={isWeekDataFetching}
          handleWeekScroll={handleWeekScroll}
        />
        {/* Rendering schedulerdata */}
        <div
          className="row calender-schedule-container flex-nowrap"
          ref={taskParentRef}
        >
          {dateListData?.map((data, indexDateListData) => (
            <div
              className="col-xl-4"
              ref={
                indexDateListData === dateListData.length - 1
                  ? scrollRightDivRef
                  : indexDateListData === 1
                  ? scrollLeftDivRef
                  : null
              }
              key={data.weekNumber}
            >
              <div>
                <div className="day">
                  <div className="row row-heading">
                    <div className="col-xl-12 text-center week">
                      Week {data?.weekNumber}
                    </div>
                  </div>
                  <div className="row row-heading">
                    {data?.dateColumnLists?.map((item, index) => (
                      <div className="col text-center day px-0" key={`${item.date}-index`}>
                        {item.date}
                      </div>
                    ))}
                  </div>
                  <div className="row row-heading">
                    {data?.dateColumnLists?.map((item, index) => (
                      <div className="col text-center day px-0" key={item.weekDay}>
                        {item.weekDay}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Working with stickiy header */}
    </div>
  );
};

export default StickyTableHeader;
