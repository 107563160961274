import React from 'react';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import ColumnSearchModal from './ColumnSearchModal';
import { useState } from 'react';
import { availableFields } from '../../lib/helpers/utils';

const ColumnSearchField = ({
  leftFilterBarRef,
  truckListColumnData,
  handleColumnFilter,
  handleColumnFilterChange,
  onChangeFilterDatePicker,
  columnFilterParams,
}) => {
  const [isColumnSearchModalOpen, setIsColumnSearchModalOpen] = useState(false);
  const [selectedColumnValue, setSelectedColumnValue] = useState([]);
  const [selectedColumnTitle, setSelectedColumnTitle] = useState("");
  const [selectedColumnName, setSelectedColumnName] = useState("");

  const handleColumnSelect = (value, title, name) => {
    setIsColumnSearchModalOpen(true);
    setSelectedColumnValue(value);
    setSelectedColumnTitle(title);
    setSelectedColumnName(name)
  }

  console.log({columnFilterParams})

  return (
    <div className="row row-filter row-heading" ref={leftFilterBarRef}>
      {
        availableFields.map(item => (
          <>
            {
              !(item.value === "receipt_date_planned" || item.value === "planned_delivery_date") ? (<div className={`filter-item-search`}>
                <div className={`input-group mb-3 my-3 d-flex ${columnFilterParams[`column_filter_${item.value}`]?.length ? "filter-applied" : ""}`} onClick={() => handleColumnSelect(columnFilterParams[`column_filter_${item.value}`], item.label, item.value)}>
                  <div className="search">
                    <p>Search</p>
                  </div>
                  <button className="btn" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                </div>
              </div>) : (
                <div className={`filter-item-search`}>
                  <div className="input-group mb-3 my-3 d-flex">
                    <DatePicker
                      style={{ width: '80%' }}
                      onChange={(date, dateString) =>
                        onChangeFilterDatePicker(date, item.value)}
                      suffixIcon={null} allowClear
                      format={"DD-MM-YYYY"}
                      defaultValue={(columnFilterParams[`column_filter_${item.value}`])
                        ? moment(columnFilterParams[`column_filter_${item.value}`])
                        : null} />
                    <button className="btn" type="button" id="filter"><i className="fa-solid fa-magnifying-glass"></i></button>
                  </div>
                </div>
              )
            }
          </>
        ))
      }

      {
        isColumnSearchModalOpen && (
          <ColumnSearchModal
            open={isColumnSearchModalOpen}
            setOpen={setIsColumnSearchModalOpen}
            value={selectedColumnValue}
            columnName={selectedColumnName}
            title={selectedColumnTitle}
            handleColumnFilter={handleColumnFilter}
            truckListColumnData={truckListColumnData}
            handleColumnFilterChange={handleColumnFilterChange}
          />
        )
      }


    </div>
  );
};

export default ColumnSearchField;